<template>
  <div>
    <swiper ref="swiperRef" v-if="data.noticeList&&data.noticeList.length" class="notice-box" :options="swiperOptions">
      <swiper-slide v-for="item in data.noticeList" :key="item.noticeId">
        <icon-svg class="icon-notice" :class="{ warn: item.level === 2 }" icon-class="icon-notice" />
        <span>
          {{ item.title }}【{{ item.timeDesc }}】
          <a-button type="link" @click="checkNoticeDetail(item)">查看详情</a-button>
        </span>
      </swiper-slide>
    </swiper>
    <!-- 整体概况 -->
    <div class="card-box">
      <div class="card-title">
        <div class="card-text">整体概况</div>
        <div class="card-date">更新时间：{{ nowDate }}</div>
        <div class="card-btn-link" @click="updateData">更新</div>
      </div>
      <div class="flex-box">
        <div class="overview-left">
          <div class="overview-row">
            <div class="overview-left-top-item">
              <span class="label">今日支付订单数</span>
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <template slot="content">
                    <p>昨日：{{ data.lastPayOrder }}</p>
                  </template>
                  <countTo class="value" ref="number1" :startVal="0" :endVal="data.nowPayOrder || 0" :duration="duration"></countTo>
                  <icon-svg style="margin-left:4px;margin-bottom:2px;" v-if="data.nowPayOrder !== data.lastPayOrder" :icon-class="
                      data.nowPayOrder > data.lastPayOrder
                        ? 'data.icon-up'
                        : 'icon-down'
                    " />
                </a-popover>
              </div>
            </div>
            <div class="overview-left-top-item">
              <span class="label">今日支付金额（元）</span>
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <template slot="content">
                    <p>昨日：{{ data.lastPayAmount }}</p>
                  </template>
                  <countTo class="value" ref="number2" :startVal="0" :endVal="data.nowPayAmount || 0" :decimals="2" :duration="duration"></countTo>
                  <icon-svg style="margin-left:4px;margin-bottom:2px;" v-if="data.lastPayAmount !== data.nowPayAmount" :icon-class="
                      data.nowPayAmount > data.lastPayAmount
                        ? 'data.icon-up'
                        : 'icon-down'
                    " />
                </a-popover>
              </div>
            </div>
            <div class="overview-left-top-item">
              <span class="label">购物券总额</span>
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <countTo class="value" ref="number11" :startVal="0" :endVal="data.beanAmount || 0" :decimals="2" :duration="duration"></countTo>
                </a-popover>
              </div>
            </div>
            <div class="overview-left-top-item">
              <span class="label">积分总额</span>
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <countTo class="value" ref="number12" :startVal="0" :endVal="data.accountAmount || 0" :decimals="2" :duration="duration"></countTo>
                </a-popover>
              </div>
            </div>
            <div class="overview-left-top-item">
              <span class="label">提现总额</span>
              <div style="cursor: pointer;">
                <a-popover placement="right">
                  <countTo class="value" ref="number12" :startVal="0" :endVal="data.txAmount || 0" :decimals="2" :duration="duration"></countTo>
                </a-popover>
              </div>
            </div>
            <div class="overview-left-top-item"></div>
          </div>
          <div class="overview-row" style="margin-top:44px">
            <div class="overview-left-bottom-item">
              <span class="label">待发货订单</span>
              <div>
                <countTo class="value" ref="number3" :startVal="0" :endVal="data.waitSendOrder || 0" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-left-bottom-item">
              <span class="label">待退款订单</span>
              <div>
                <countTo class="value" ref="number4" :startVal="0" :endVal="data.waitRefundOrder || 0" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-left-bottom-item">
              <span class="label">库存过少商品</span>
              <div>
                <countTo class="value" ref="number5" :startVal="0" :endVal="data.stockLessProduct || 0" :duration="duration"></countTo>
              </div>
            </div>
          </div>
        </div>
        <div class="overview-right">
          <!-- <div class="overview-row">
            <div class="overview-right-item">
              <span class="label">可提现金额（元）</span>
              <div>
                <countTo class="value" ref="number6" :startVal="0" :endVal="data.cashAmount || 0" :decimals="2" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-right-item">
              <span class="label">待结算（元）</span>
              <div>
                <countTo class="value" ref="number7" :startVal="0" :endVal="data.uncashAmount || 0" :decimals="2" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-right-item"></div>
          </div>
          <div class="overview-row" style="margin-top:44px">
            <div class="overview-right-item">
              <span class="label">供应商数</span>
              <div>
                <countTo class="value" ref="number9" :startVal="0" :endVal="data.partnerUp || 0" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-right-item">
              <span class="label">分销商数量</span>
              <div>
                <countTo class="value" ref="number10" :startVal="0" :endVal="data.partnerDown || 0" :duration="duration"></countTo>
              </div>
            </div>
            <div class="overview-right-item"></div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 智能助手 -->
    <div class="card-box">
      <div class="card-title">
        <div class="card-text">智能助手</div>
      </div>
      <div class="ai-assistant">
        <div class="assistant-item" @click="$router.push('/goods/publish/add')">
          <icon-svg class="assistant-item-img" icon-class="icon-16" />
          <span class="assistant-item-label">新品发布</span>
        </div>
        <div class="assistant-item" @click='$router.push("/order/goods_order?state=1")'>
          <icon-svg class="assistant-item-img" icon-class="icon-17" />
          <span class="assistant-item-label">订单发货</span>
        </div>
        <div class="assistant-item" @click='$router.push("/order/goods_order?state=6")'>
          <icon-svg class="assistant-item-img" icon-class="icon-18" />
          <span class="assistant-item-label">售后处理</span>
        </div>
        <div class="assistant-item" @click="$router.push('/shop/renovation')">
          <icon-svg class="assistant-item-img" icon-class="icon-19" />
          <span class="assistant-item-label">店铺装修</span>
        </div>
        <div class="assistant-item" @click="getPostImg(1)">
          <icon-svg class="assistant-item-img" icon-class="icon-20" />
          <span class="assistant-item-label">邀请供应商</span>
        </div>
        <div class="assistant-item" @click="getPostImg(2)">
          <icon-svg class="assistant-item-img" icon-class="icon-21" />
          <span class="assistant-item-label">邀请分销商</span>
        </div>
      </div>
    </div>
    <!-- 学习交流 -->
    <div class="card-box">
      <div class="card-title">
        <div class="card-text">学习交流</div>
      </div>
      <div class="learning-box">
        <div class="learning-box-item" @click="$router.push('/guid_page/1')">
          <div class="learning-box-item-title">如何发布商品</div>
          <div class="learning-box-item-desc">教您如何快速上架商品</div>
        </div>
        <div class="learning-box-item" @click="$router.push('/guid_page/2')">
          <div class="learning-box-item-title">发展分销商</div>
          <div class="learning-box-item-desc">教您如何快速发展分销商</div>
        </div>
        <div class="learning-box-item" @click="$router.push('/guid_page/3')">
          <div class="learning-box-item-title">发展供应商</div>
          <div class="learning-box-item-desc">发展更多供应商</div>
        </div>
        <div class="learning-box-item" @click="$router.push('/guid_page/4')">
          <div class="learning-box-item-title">订单管理</div>
          <div class="learning-box-item-desc">快速上手管理订单</div>
        </div>
        <!-- <div class="learning-box-item">
          <div class="learning-box-item-title">如何快速批量发货</div>
          <div class="learning-box-item-desc">学习系统之间对接串货</div>
        </div> -->
        <div class="learning-box-item" @click="$router.push('/helper')">
          <div class="learning-box-item-title">更多问题</div>
          <div class="learning-box-item-desc">疑问可以在这找到答案</div>
        </div>
      </div>
    </div>
    <a-modal class="post-img-modal" v-model="postModalShow" :closable="false" :width="375" :footer="null">
      <div class="post-img-box">
        <img v-if="postImg" :src="postImg" alt="" class="post-img" />
        <p v-else>二维码生成失败</p>
      </div>
    </a-modal>
    <!-- 查看通知公告 -->
    <a-modal v-model="noticeDetailShow" width="840px" :maskClosable="false" :footer="null">
      <div slot="title">
        <a-tag :color="currentNotice.level === 1 ? 'blue' : 'red'">{{
          currentNotice.level === 1 ? "普通" : "紧急"
        }}</a-tag>{{ currentNotice.title }}
      </div>
      <p>{{ currentNotice.timeDesc }}</p>
      <div v-html="currentNotice.content"></div>
    </a-modal>
  </div>
</template>
<script>
import { getSession } from '@/utils/session'
import { SHOPID, SHOPSTAFFID, TOKEN } from '../constants'
import countTo from 'vue-count-to'
import { reactive, toRefs, onMounted, ref } from '@vue/composition-api'
import { system } from '@/api'
import moment from 'moment'
import axios from 'axios'
export default {
  name: 'AppDashboard',
  components: { countTo },
  setup (props, { root }) {
    const state = reactive({
      postModalShow: false,
      postImg: '',
      nowDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      data: {},
      duration: 1000,
      number1: null,
      number2: null,
      number3: null,
      number4: null,
      number5: null,
      number6: null,
      number7: null,
      number8: null,
      number9: null,
      number10: null,
      number11: null,
      number12: null,
      baseImgUrl: process.env.VUE_APP_IMG
    })
    onMounted(() => {
      getData()
    })

    const swiperOptions = ref({
      direction: 'vertical',
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    })
    const currentNotice = ref({})
    const noticeDetailShow = ref(false)
    function checkNoticeDetail (data) {
      currentNotice.value = data
      noticeDetailShow.value = true
    }

    async function getData () {
      let { code, data, msg } = await system.getIndexData({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.data = data
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function updateData () {
      getData()
      for (let i = 1; i <= 5; i++) {
        state['number' + i].start()
      }

      state.nowDate = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
    }
    async function getPostImg (type) {
      if (type === 2) {
        axios({
          url: '/shopFollow/createActivityQrCode',
          baseURL: process.env.VUE_APP_API,
          headers: { Authorization: getSession(TOKEN) },
          params: {
            shopId: getSession(SHOPID),
            followType: type,
          },
          responseType: 'arraybuffer',
        })
          .then((response) => {
            // 将从后台获取的图片流进行转换
            return (
              'data:image/png;base64,' +
              btoa(
                new Uint8Array(response.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ''
                )
              )
            )
          })
          .then((data) => {
            if (data) {
              showPostImgModal(data)
            }
          })
          .catch(() => {
            root.$message.error('生成二维码失败')
          })
      } else {
        const { data, msg, code } = await system.getStaffPoster({
          shopStaffId: getSession(SHOPSTAFFID)
        })
        if (code === '00000') {
          showPostImgModal(state.baseImgUrl + data)
        } else {
          root.$message.error(msg || '获取海报图片失败')
        }
      }
    }
    function showPostImgModal (data) {
      state.postImg = data
      state.postModalShow = true
    }
    return {
      ...toRefs(state),
      currentNotice,
      noticeDetailShow,
      checkNoticeDetail,
      swiperOptions,
      getData,
      moment,
      updateData,
      getPostImg,
      showPostImgModal,
    }
  },
}
</script>

<style lang="less" scoped>
.notice-box {
  height: 42px;
  border: 2px solid #f7d2b7;
  background: #fff5ec;
  line-height: 18px;
  padding: 4px 12px;
  .icon-notice {
    color: @main-color;
    font-size: 16px;
    margin-right: 8px;
    &.warn {
      color: @warning-color;
    }
  }
}
.card-box {
  background: #fff;
  margin-top: 12px;
  padding: 24px 0;
  border: 1px solid #dcdfe6;
  .card-title {
    display: flex;
    align-items: flex-end;
    border-left: 4px solid #e9392a;
    padding-left: 16px;
    height: 16px;
    line-height: 16px;
    .card-text {
      font-weight: bolder;
      font-size: 16px;
      color: #000;
    }
    .card-date {
      color: #666;
      font-size: 12px;
      margin-left: 16px;
    }
    .card-btn-link {
      cursor: pointer;
      color: @main-color;
      font-size: 14px;
      margin-left: 16px;
    }
  }
  .flex-box {
    display: flex;
    min-height: 150px;
    margin-top: 44px;
  }
  .ai-assistant {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    .assistant-item {
      width: calc(100% / 6);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // margin-bottom: 24px;
      .assistant-item-img {
        width: 48px;
        height: 48px;
      }
      .assistant-item-label {
        color: #666;
        font-size: 12px;
        margin-top: 8px;
        line-height: 12px;
      }
    }
  }
  .learning-box {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    padding: 0 16px;
    .learning-box-item {
      width: calc((100% - 140px) / 5);
      min-height: 92px;
      text-align: center;
      box-sizing: border-box;
      background: #f1f2f6;
      margin-right: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:nth-of-type(6n) {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.1);
      }
      .learning-box-item-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 4px;
        line-height: 14rpx;
        color: #666;
      }
      .learning-box-item-desc {
        font-size: 12px;
        line-height: 12rpx;
        color: #999;
      }
    }
  }
  .overview-left {
    flex: 1;
    // border-right: 1px solid #ddd;
    .overview-row {
      display: flex;
      justify-content: center;
      margin-left: 48px;
    }
    .overview-left-top-item {
      width: 150px;
      .value {
        color: #3386ff;
        font-weight: bold;
        font-size: 20px;
      }
      .label {
        color: #666;
      }
    }
    .overview-left-bottom-item {
      width: calc(100% / 3);
      .value {
        color: #e83928;
        font-weight: bold;
        font-size: 20px;
      }
      .label {
        color: #666;
      }
    }
  }
  .overview-right {
    flex: 1;
    .overview-row {
      display: flex;
      justify-content: center;
      margin-left: 48px;
    }
    .overview-right-item {
      width: calc(100% / 3);
      .value {
        color: #000;
        font-weight: bold;
        font-size: 20px;
      }
      .label {
        color: #666;
      }
    }
  }
}
.post-img-modal ::v-deep .ant-modal-body {
  padding: 0;
}
.post-img {
  width: 100%;
  object-fit: contain;
}
</style>
