/*
 * @Author: your name
 * @Date: 2021-09-27 21:19:24
 * @LastEditTime: 2022-01-06 19:58:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \mms\src\api\modules\agent.js
 */
import { service } from '@/utils/request'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
export default {
  getTeamLevelList (payload) {
    return service.get('/agent/team/level/list', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  saveTeamLevel (payload) {
    return service.post('/agent/team/level/save', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  changeState (payload) {
    return service.post('/agent/team/changeState', {
      ...payload,
      shopId: getSession(SHOPID)
    })
  },
  changeUpUser (payload) {
    return service.postForm('/agent/team/changeUpUser', payload)
  },
  deleteUser (payload) {
    return service.postForm('/agent/team/deleteUser', payload)
  },
  deleteTeamLevel (payload) {
    return service.postForm('/agent/team/level/delete', payload)
  },
  getTeamPage (payload) {
    return service.get('/agent/team/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getChild (payload) {
    return service.get('/agent/team/getChild', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getTeamInfoData () {
    return service.get('/agent/team/info', {
      params: {
        shopId: getSession(SHOPID)
      }
    })
  },
  getCommissionChangeLog (payload) {
    return service.get('/userCommission/pageChangeLog', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  usersByLevel (payload) {
    return service.get('/agent/team/usersByLevel', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  changeUserLevel (payload) {
    return service.postForm('/agent/team/updateLevel', payload)
  },
  getAgentSales (payload) {
    return service.get('/agent/team/sales/page', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getAgentSalesTotal (payload) {
    return service.get('/agent/team/sales/page/total', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getTeamSales (payload) {
    return service.get('/agent/team/getSalesInfo', {
      params: {
        ...payload,
        shopId: getSession(SHOPID)
      }
    })
  },
  getUserPage (payload) {
    return service.get('/agent/team/pageUserRegion', {
      params: {
        shopId: getSession(SHOPID),
        ...payload
      }
    })
  },
  userRegionAdd (payload) {
    return service.post('/agent/team/userRegionAdd', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  // 删除代理
  deleteDl (payload) {
    return service.postForm('/agent/team/deleteDl', payload)
  },
  saveParameter (payload) {
    return service.post('/agent/team/saveParameter', {
      shopId: getSession(SHOPID),
      ...payload
    })
  },
  getParameter () {
    return service.get('/agent/team/getParameter', {
      params: {
        shopId: getSession(SHOPID),
      }
    })
  },
  regisDown (payload) {
    return service.postForm('/agent/team/regisDown', payload)
  },
}
