import AppLayout from '@/views/layout/AppLayout'

export default {
  path: '/capital',
  component: AppLayout,
  children: [
    {
      path: 'balance',
      component: () => import('@/views/capital/PageBalance.vue'),
      meta: { title: '店铺余额', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'bank_card',
      component: () => import('@/views/capital/PageBankCard.vue'),
      meta: { title: '我的银行卡', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'balance/record',
      component: () => import('@/views/capital/PageBalanceRecord.vue'),
      meta: { title: '提现记录', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'recommend',
      component: () => import('@/views/capital/PageRecommend.vue'),
      meta: { title: '推荐奖励', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'recommend/record/:shopId?',
      component: () => import('@/views/capital/PageRecommendRecord.vue'),
      meta: { title: '奖励记录', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'cash_coupon_remain',
      component: () => import('@/views/capital/PageCashCouponRemain.vue'),
      meta: { title: '代金券余额', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge',
      component: () => import('@/views/capital/PageCharge.vue'),
      meta: { title: '充值管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/account/history',
      component: () => import('@/views/capital/PageChargeAccountHistory.vue'),
      meta: { title: '充值变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_balance',
      component: () => import('@/views/capital/PageChargeBalance.vue'),
      meta: { title: '购物积分管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/balance/history',
      component: () => import('@/views/capital/PageChargeBalanceHistory.vue'),
      meta: { title: '购物积分变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge_bean',
      component: () => import('@/views/capital/PageChargeBean.vue'),
      meta: { title: '消费积分管理', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/bean/history',
      component: () => import('@/views/capital/PageChargeBeanHistory.vue'),
      meta: { title: '消费积分变动记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/xhcd/history',
      component: () => import('@/views/capital/PageChargeXhcdHistory.vue'),
      meta: { title: '现货仓单记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/bao/history',
      component: () => import('@/views/capital/PageChargeBaoHistory.vue'),
      meta: { title: '积分保记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'charge/baoBill/history',
      component: () => import('@/views/capital/PageChargeBaoBillHistory.vue'),
      meta: { title: '积分保收益记录', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'report/goods/self/income',
      component: () => import('@/views/capital/PageReportGoodsSelfIncome.vue'),
      meta: { title: '自营商品收入报表', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'report/goods/third/income',
      component: () => import('@/views/capital/PageReportGoodsThirdIncome.vue'),
      meta: { title: '分销商品收入报表', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'rank/income',
      component: () => import('@/views/capital/PageRankGoodsIncome.vue'),
      meta: { title: '商品交易排名', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'settle/dashboard',
      component: () => import('@/views/capital/PageSettleDashboard.vue'),
      meta: { title: '账户总览', noClose: false, showInMenuFlag: true }
    },
    {
      path: 'settle/wait',
      component: () => import('@/views/capital/PageSettleWait.vue'),
      meta: { title: '待结算订单明细', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'settle/can',
      component: () => import('@/views/capital/PageSettleCan.vue'),
      meta: { title: '可结算订单明细', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'settle/already',
      component: () => import('@/views/capital/PageSettleAlready.vue'),
      meta: { title: '已结算订单明细', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'invoice/service',
      component: () => import('@/views/capital/PageInvoiceService.vue'),
      meta: { title: '平台服务费发票申请', noClose: false, showInMenuFlag: true, noCache: true }
    },
    {
      path: 'invoice/service/request',
      component: () => import('@/views/capital/PageInvoiceForm.vue'),
      meta: { title: '申请开票', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'invoice/consumer',
      component: () => import('@/views/capital/PageInvoiceCustomer.vue'),
      meta: { title: '消费者发票上传', noClose: false, showInMenuFlag: true, }
    },
    {
      path: 'invoice/commission',
      component: () => import('@/views/capital/PageInvoiceCommission.vue'),
      meta: { title: '应开平台发票', noClose: false, showInMenuFlag: true, noCache: true }
    },
    {
      path: 'invoice/commission/create',
      component: () => import('@/views/capital/PageInvoiceForm.vue'),
      props: {
        directFlag: true
      },
      meta: { title: '开票', noClose: false, showInMenuFlag: false }
    },
    {
      path: 'user_qyq',
      component: () => import('@/views/capital/PageUserQyq.vue'),
      meta: { title: '用户权益券', noClose: false, showInMenuFlag: true }
    },
  ]
}
